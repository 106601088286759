import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/footer";
import NavBar from "./components/navBar";
import Home from "./routes/home";

export default function App() {
  return (
    <div className="bg-white">
      <NavBar/>
      <Router>
        <Routes>
          <Route path="/" element={<Home />}/>
        </Routes>
      </Router>
      <Footer/>
    </div>
  );
}