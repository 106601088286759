
// import LogoCloudSection from "../components/logoCloudSection";
import FeatureImgSection from "../components/featureImgSection";
import FeatureTextSection from "../components/featureTextSection";
import NewsletterSection from "../components/newsletterSection";
import HeroSectionNew from "../components/heroSectionNew";
import FAQSection from "../components/faqSection";
// import TestimonialSection from "../components/testimonialSection";

export default function Home() {
  return (
    <main>
      <HeroSectionNew/>
      <FeatureTextSection />
      <FeatureImgSection />
      <FAQSection />
      <NewsletterSection />
      {/* <TestimonialSection /> */}
    </main>
  );
}
