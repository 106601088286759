import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "How do I add existing influencer product reviews?",
    answer:
      "It's as simple as pasting the link to the video into our admin panel. We'll take care of the rest.",
  },
  {
    question: "Do you support API access?",
    answer:
      "Yes! We have a full API that you can use to integrate our service into your site.",
  },
  {
    question: "Do you support Shopify?",
    answer:
      "Yes! Between our embedding code snippet or API, we can support any site. We have a Shopify app in the works as well.",
  },
  {
    question: "Can you help me find influencer product reviews?",
    answer:
      "Yes! We have a full service influencer marketing agency that can help you find influencers and get them to review your products.",
  },
  {
    question: "Can you help me integrate this into my site?",
    answer:
      "Yes! We have a support service plans that can help you integrate this into your site.",
  },
  {
    question: "How much does it cost?",
    answer:
      "We have a generous free tier that allows you to get started for free. Our paid plans are dependent on your site's traffic.",
  },
  {
    question: "How do I get started?",
    answer:
      "Click the Get Started button below to get in touch with us.",
  },
]

export default function FAQSection() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
