import {
  LockClosedIcon,
  ArrowPathIcon,
  CloudArrowDownIcon,
} from "@heroicons/react/24/outline";

const secondaryFeatures = [
  {
    name: "Content Discovery",
    description:
      "We have a team of content curators and the best software tools available to find short-form videos about your brand from around the web.",
    href: "/",
    icon: CloudArrowDownIcon,
  },
  {
    name: "First Party Data",
    description:
      "Our deep partnerships with social platforms and creators enable us to deliver you unique first party data about which short form videos to leverage that you can't get anywhere else.",
    href: "/",
    icon: LockClosedIcon,
  },

  {
    name: "Easy Integration",
    description:
      "We make this process as easy as copying a single line of code into your online store. We are compatible with all major e-commerce platforms or custom sites.",
    href: "/",
    icon: ArrowPathIcon,
  },
];

export default function FeatureTextSection() {
  return (
    <div className="mx-auto py-32 max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl lg:text-center">
        <h2 className="text-base font-semibold leading-7 text-indigo-600">
          Sell more
        </h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Everything you need to convert more visitors
        </p>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Our first party data helps us find and curate the best converting
          short-form video content for your brand & we make it super easy to
          integrate into your online store.
        </p>
      </div>
      <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
          {secondaryFeatures.map((feature) => (
            <div key={feature.name} className="flex flex-col">
              <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                <feature.icon
                  className="h-5 w-5 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                {feature.name}
              </dt>
              <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                <p className="flex-auto">{feature.description}</p>
                <p className="mt-6">
                  <a
                    href={feature.href}
                    className="text-sm font-semibold leading-6 text-indigo-600"
                  >
                    Learn more <span aria-hidden="true">→</span>
                  </a>
                </p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
